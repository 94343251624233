'use strict';

window.console || (window.console = {
	log: function log() {}
});
var FUNCTIONS = window.FUNCTIONS || null;
var cssua = window.cssua || null;

(function ($) {
	var htmlHasClass = function htmlHasClass(_class) {
		return $('html').hasClass(_class);
	};
	var siteName = 'e-maruman';
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			isMobile: htmlHasClass('ua-mobile'),
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
						pos -= 60;
						if (hash === '#purchase') {
							pos -= 90;
						}
					} else {
						pos -= 50;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash: function easeScrollHash() {
			var hash = location.hash;
			if (hash) {
				$(window).on('load', function () {
					$('html, body').css('display', 'none');

					setTimeout(function () {
						$('html, body').css('display', 'block');

						var _hash = '#' + hash.split('#')[1];
						var pos = $(_hash).offset().top;

						if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
							pos -= 60;
							if (_hash === '#purchase') {
								pos -= 90;
							}
						} else {
							pos -= 50;
						}
						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					}, 100);
				});
			}
		},
		pageTop: function pageTop() {
			if ($('.footer-pagetop').length) {
				var $pagetop = $('.footer-pagetop');
				if (!$('.factory-footer').length) {
					$(window).scroll(function (e) {
						var $this = $(e.currentTarget);
						var $footer = $('.footer');
						var footerPos = $footer.offset().top + $footer.height();
						var thisScroll = $this.scrollTop();
						var pagetopPos = $this.height() + $footer.height() + thisScroll;

						if (pagetopPos > footerPos + 70) {
							$pagetop.addClass(f);
						} else {
							$pagetop.removeClass(f);
						}
						if (thisScroll > '150') {
							$pagetop.addClass(v);
						} else {
							$pagetop.removeClass(v);
						}
					});
				} else {
					$pagetop = $('.factory-footer .footer-pagetop');
					$(window).scroll(function (e) {
						var $this = $(e.currentTarget);
						var $footer = $('.footer');
						var footerPos = $footer.offset().top + $footer.height();
						var thisScroll = $this.scrollTop();
						var pagetopPos = $this.height() + $footer.height() + thisScroll;

						if (pagetopPos > footerPos + 70) {
							$pagetop.addClass(f);
						} else {
							$pagetop.removeClass(f);
						}
					});
					var timeoutId;
					$(window).scroll(function (e) {
						$pagetop.removeClass(v);
						clearTimeout(timeoutId);
						timeoutId = setTimeout(function () {
							$pagetop.addClass(v);
						}, 1500);
					});
				}
			}
		},
		modal: function modal() {
			$('.mod-modal').on('click', function (e) {
				var HTML = '<div class="mod-modal-overlay">';
				HTML += '<div class="mod-modal-closeArea"></div>' + '<span class="mod-modal-closeBtn"><span></span></span>' + ($(e.currentTarget).find('img').prop('outerHTML') + '</div>');
				$('body').append(HTML);
				var $overlay = $('.mod-modal-overlay');
				$overlay.addClass('is-active');
				setTimeout(function () {
					$overlay.addClass('is-visible');
					$('.mod-modal-closeArea, .mod-modal-closeBtn').on('click', function () {
						$overlay.removeClass('is-visible');
						setTimeout(function () {
							$overlay.removeClass('is-active');
							$overlay.remove();
						}, 400);
					});
				});
			});
		},
		headerMenu: function headerMenu() {
			var $menu = $('.js-header-menu');
			var $menucontent = $('.js-header-menu-content');
			var $menuback = $('.js-header-menu-back');
			var $headerSearchBtn = $('.js-header-search-btn');
			var $headerSearchHidden = $('.header-right-li-search-hidden');
			var flag = false;
			var scrollTopNow;

			// サイト内検索部分
			$headerSearchBtn.on('click', function () {
				$(this).toggleClass(a);
				$('.js-header-search-blockWrap').toggleClass(a);
				$headerSearchHidden.toggleClass('di_none');
			});
			$('.js-header-search-block-close').on('click', function () {
				$('.js-header-search-blockWrap').removeClass(a);
				$headerSearchBtn.removeClass(a);
				$headerSearchHidden.removeClass('di_none');
			});
			$('.contents').on('click', function () {
				$('.js-header-search-blockWrap').removeClass(a);
				$headerSearchBtn.removeClass(a);
				$headerSearchHidden.removeClass('di_none');
			});
			$('._search-close').on('click', function () {
				$('.js-header-search-blockWrap').removeClass(a);
				$headerSearchBtn.removeClass(a);
				$headerSearchHidden.removeClass('di_none');
			});
			$('.header-bg').on('click', function () {
				$('.js-header-search-blockWrap').removeClass(a);
				$headerSearchBtn.removeClass(a);
				$headerSearchHidden.removeClass('di_none');
			});
			// お気に入りリンク部分
			for (var i in localStorage) {
				if (i.indexOf('favItem-') !== -1) {
					$('.header-right-li._fav').addClass(a);
					$('.header-nav-content-li._fav').addClass(a);
				}
			}
			//言語選択部分
			$('.js-lang-toggle').on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-lang-toggleBlock').stop().slideToggle(600);
			});
			// $('.header-right-li .js-lang-toggle').on('mouseenter', function(){
			// 	$('.header-right-li .js-lang-toggle').addClass(a);
			// 	$('.header-right-li .js-lang-toggleBlock').stop().slideDown(600);
			// });
			$('.header-right-li .js-lang-toggleBlock').parents('.pos_relative').on('mouseleave', function () {
				$('.header-right-li .js-lang-toggle').removeClass(a);
				$('.header-right-li .js-lang-toggleBlock').stop().slideUp(600);
			});
			// 製品検索部分
			// サブミット
			$('.js-search-header-submit').on('click', function () {
				var kewords = $(this).parents('form').find('.js-search-header-keyword').val().replaceAll("　", " ").split(/\s/);
				var keywordInputs = void 0;
				for (var _i = 0; _i < kewords.length; _i++) {
					if (_i == 0) {
						keywordInputs = '<input name="p_keyword_1" type="hidden" value="' + kewords[_i] + '" />';
						$(this).parents('form').append(keywordInputs);
					} else if (_i == 1) {
						keywordInputs = '<input name="p_keyword_2" type="hidden" value="' + kewords[_i] + '" />';
						$(this).parents('form').append(keywordInputs);
					} else if (_i == 2) {
						keywordInputs = '<input name="p_keyword_3" type="hidden" value="' + kewords[_i] + '" />';
						$(this).parents('form').append(keywordInputs);
					} else if (_i == 3) {
						keywordInputs = '<input name="p_keyword_4" type="hidden" value="' + kewords[_i] + '" />';
						$(this).parents('form').append(keywordInputs);
					} else if (_i == 4) {
						keywordInputs = '<input name="p_keyword_5" type="hidden" value="' + kewords[_i] + '" />';
						$(this).parents('form').append(keywordInputs);
					} else if (_i == 5) {
						keywordInputs = '<input name="p_keyword_6" type="hidden" value="' + kewords[_i] + '" />';
						$(this).parents('form').append(keywordInputs);
					} else if (_i == 6) {
						keywordInputs = '<input name="p_keyword_7" type="hidden" value="' + kewords[_i] + '" />';
						$(this).parents('form').append(keywordInputs);
					} else if (_i == 7) {
						keywordInputs = '<input name="p_keyword_8" type="hidden" value="' + kewords[_i] + '" />';
						$(this).parents('form').append(keywordInputs);
					} else if (_i == 8) {
						keywordInputs = '<input name="p_keyword_9" type="hidden" value="' + kewords[_i] + '" />';
						$(this).parents('form').append(keywordInputs);
					} else if (_i == 9) {
						keywordInputs = '<input name="p_keyword_10" type="hidden" value="' + kewords[_i] + '" />';
						$(this).parents('form').append(keywordInputs);
					}
				}
				$(this).parents('form').submit();
			});
			$('.js-search-header-keyword').keypress(function (e) {
				if (e.keyCode == 13) {
					var kewords = $(this).val().replaceAll("　", " ").split(/\s/);
					var keywordInputs = void 0;
					for (var _i2 = 0; _i2 < kewords.length; _i2++) {
						if (_i2 == 0) {
							keywordInputs = '<input name="p_keyword_1" type="hidden" value="' + kewords[_i2] + '" />';
							$(this).parents('form').append(keywordInputs);
						} else if (_i2 == 1) {
							keywordInputs = '<input name="p_keyword_2" type="hidden" value="' + kewords[_i2] + '" />';
							$(this).parents('form').append(keywordInputs);
						} else if (_i2 == 2) {
							keywordInputs = '<input name="p_keyword_3" type="hidden" value="' + kewords[_i2] + '" />';
							$(this).parents('form').append(keywordInputs);
						} else if (_i2 == 3) {
							keywordInputs = '<input name="p_keyword_4" type="hidden" value="' + kewords[_i2] + '" />';
							$(this).parents('form').append(keywordInputs);
						} else if (_i2 == 4) {
							keywordInputs = '<input name="p_keyword_5" type="hidden" value="' + kewords[_i2] + '" />';
							$(this).parents('form').append(keywordInputs);
						} else if (_i2 == 5) {
							keywordInputs = '<input name="p_keyword_6" type="hidden" value="' + kewords[_i2] + '" />';
							$(this).parents('form').append(keywordInputs);
						} else if (_i2 == 6) {
							keywordInputs = '<input name="p_keyword_7" type="hidden" value="' + kewords[_i2] + '" />';
							$(this).parents('form').append(keywordInputs);
						} else if (_i2 == 7) {
							keywordInputs = '<input name="p_keyword_8" type="hidden" value="' + kewords[_i2] + '" />';
							$(this).parents('form').append(keywordInputs);
						} else if (_i2 == 8) {
							keywordInputs = '<input name="p_keyword_9" type="hidden" value="' + kewords[_i2] + '" />';
							$(this).parents('form').append(keywordInputs);
						} else if (_i2 == 9) {
							keywordInputs = '<input name="p_keyword_10" type="hidden" value="' + kewords[_i2] + '" />';
							$(this).parents('form').append(keywordInputs);
						}
					}
					$(this).parents('form').submit();
					return false;
				}
			});

			// ハンバーガーメニュー
			$menu.on('click', function () {
				if (!flag) {
					scrollTopNow = document.body.scrollTop + document.documentElement.scrollTop;
					$menu.addClass(a);
					$menucontent.addClass(a);
					$('.container').css({
						position: 'fixed',
						width: '100%',
						top: -1 * scrollTopNow,
						left: 0
					});
					$('body').addClass(f);
					flag = true;
				} else {
					$menu.removeClass(a);
					$menucontent.removeClass(a);
					$('.container').css({
						position: 'static'
					});
					$('body').removeClass(f);

					$(window).scrollTop(scrollTopNow);
					flag = false;
				}
			});
			$menuback.on('click', function () {
				$menu.removeClass(a);
				$menucontent.removeClass(a);
				$('.container').css({
					position: 'static'
				});
				$('body').removeClass(f);

				$(window).scrollTop(scrollTopNow);
				$('.header-nav-content-li-in').removeClass(v);
				$('.header-nav-content-li_menu').stop().slideUp(400);
				flag = false;
			});
		},
		headerNav: function headerNav() {
			var $headernav = $('.header-nav');
			$(window).scroll(function () {
				var navPos = 130 - $(window).scrollTop();
				if ($(window).scrollTop() >= $('.header').height()) {
					$headernav.addClass(f);
					if (!(FUNCTIONS.va.window.width < 1024)) {
						$('.header-nav-content-li_menu').css('top', 50);
					}
				} else {
					$headernav.removeClass(f);
					if (!(FUNCTIONS.va.window.width < 1024)) {
						$('.header-nav-content-li_menu').css('top', navPos);
					}
				}
			});
		},
		slider: function slider() {
			if ($('.mod-slide01').length) {
				$('.mod-slide01').slick({
					autoplay: true,
					autoplaySpeed: 5000,
					slidesToShow: 1,
					slidesToScroll: 1,
					loop: true,
					arrows: true,
					prevArrow: '<a class="slick-prev" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_left" /></svg></a>',
					nextArrow: '<a class="slick-next" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_right" /></svg></a>',
					dots: true,
					pauseOnFocus: false,
					pauseOnHover: false,
					pauseOnDotsHover: false,
					responsive: [{
						breakpoint: 768,
						settings: {
							arrows: false
						}
					}]
				});
			}
			if ($('.mod-slide02').length) {
				$('.mod-slide02').slick({
					autoplay: true,
					autoplaySpeed: 5000,
					slidesToShow: 4,
					slidesToScroll: 1,
					loop: true,
					arrows: true,
					prevArrow: '<a class="slick-prev" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_left" /></svg></a>',
					nextArrow: '<a class="slick-next" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_right" /></svg></a>',
					dots: false,
					responsive: [{
						breakpoint: 768,
						settings: {
							slidesToShow: 2
						}
					}]
				});
			}
		},
		sideNavCurrent: function sideNavCurrent() {
			if ($('.mod-sideNav-nav').length) {
				$('.mod-sideNav-nav').find('.__link').each(function (idx, ele) {
					if (FUNCTIONS.va.pathname.indexOf('/policy/mps/') !== -1) {
						if ($(ele).attr('href') === FUNCTIONS.va.pathname) {
							$(ele).addClass(a);
						}
					} else {
						var subDir = '/' + $(ele).attr('href').split('/')[2] + '/'; // 2階層目のディレクトリ名で判定
						if (FUNCTIONS.va.pathname.indexOf(subDir) !== -1) {
							$(ele).addClass(a);
						}
					}
				});
			}
		},
		bottomNavCurrent: function bottomNavCurrent() {
			if ($('.js-bottomNav').length) {
				$('.js-bottomNav').find('a').each(function (idx, ele) {
					var subDir = '/' + $(ele).attr('href').split('/')[2] + '/'; // 2階層目のディレクトリ名で判定
					if (FUNCTIONS.va.pathname.indexOf(subDir) !== -1) {
						$(ele).addClass(a);
					}
				});
			}
		},
		priceScript: function priceScript() {
			if ($('.rt_pickup_cf_p_price').length) {
				for (var i = 0; i < $('.rt_pickup_cf_p_price').length; i++) {
					var price = $('.rt_pickup_cf_p_price').eq(i).text().replace(/\\/g, '');
					var pricestring = Number(price).toLocaleString();
					$('.rt_pickup_cf_p_price').eq(i).text(pricestring + '（税込）');
					$('.rt_pickup_cf_p_price').eq(i).prepend('&#xFFE5;');
				}
			}
			if ($('.rt_cf_p_price').length) {
				for (var i = 0; i < $('.rt_cf_p_price').length; i++) {
					var _price = $('.rt_cf_p_price').eq(i).text().replace(/\\/g, '');
					var _pricestring = Number(_price).toLocaleString();
					$('.rt_cf_p_price').eq(i).text(_pricestring + '（税込）');
					$('.rt_cf_p_price').eq(i).prepend('&#xFFE5;');
				}
			}
			if ($('.js-priceText').length) {
				for (var i = 0; i < $('.js-priceText').length; i++) {
					var _price2 = $('.js-priceText').eq(i).text().replace(/\\/g, '');
					var _pricestring2 = Number(_price2).toLocaleString();
					$('.js-priceText').eq(i).text(_pricestring2 + '（税込）');
					$('.js-priceText').eq(i).prepend('&#xFFE5;');
				}
			}
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.modal();
			_this.headerMenu();
			_this.headerNav();
			_this.slider();
			_this.sideNavCurrent();
			_this.bottomNavCurrent();
			_this.priceScript();

			svg4everybody();
		}
	};

	$(function () {
		return FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript();
	});
})(window.jQuery);

$(window).on('load', function () {
	var cssua = window.cssua || null;
	var headerMegaMene = function headerMegaMene() {
		var $btn = $('.header-nav-content-li-in');
		var $item = $('.header-nav-content-li_menu');
		var $bg = $('.header-nav-content-li_menu-bg');
		var a = 'is-active';
		var v = 'is-visible';

		var agent = window.navigator.userAgent.toLowerCase();
		var ipad = agent.indexOf('ipad') > -1 || agent.indexOf('macintosh') > -1 && 'ontouchend' in document;
		if (!(ipad == true)) {
			// pc用
			if ($('.ua-desktop').length) {
				$btn.on('mouseenter', function () {
					$btn.removeClass(v);
					$bg.removeClass(v);
					$item.stop().slideUp(200);
					$(this).addClass(v);
					if (!$(this).hasClass('_online')) {
						$(this).next($item).stop().slideDown(400);
						$(this).next($item).next($bg).stop().addClass(v);
					}
				});
				$item.on('mouseleave', function () {
					$btn.removeClass(v);
					$bg.removeClass(v);
					$item.stop().slideUp(200);
				});
				$('.header .color-bg_white').on('mouseenter', function () {
					$btn.removeClass(v);
					$bg.removeClass(v);
					$item.stop().slideUp(200);
				});
				$('.header .header-nav_bg').on('mouseenter', function () {
					$btn.removeClass(v);
					$bg.removeClass(v);
					$item.stop().slideUp(200);
				});
				$('.contents').on('mouseenter', function () {
					$btn.removeClass(v);
					$bg.removeClass(v);
					$item.stop().slideUp(200);
				});
			}
		}
		// tablet sp用
		if ($('.header-nav_sp-tabchange-btn').length) {
			var tabs = $('.header-nav_sp-tabchange-btn');
			tabs.on('click', function () {
				tabs.removeClass(a);
				$(this).addClass(a);
				var index = tabs.index(this);
				$('.header-nav_sp-tabchange-content').removeClass(a).eq(index).addClass(a);
			});
		}
	};
	var pagerListScroll = function pagerListScroll() {
		if ($('.products-search-favBtn-wrap').length) {
			var urlParam = location.search.substring(1);
			var paramArray = [];
			if (urlParam) {
				var param = urlParam.split('&');
				for (var i = 0; i < param.length; i++) {
					var paramItem = param[i].split('=');
					paramArray[paramItem[0]] = paramItem[1];
				}
			}
			if (paramArray.rt_bn_product_list_skip) {
				var area = $('.products-search-favBtn-wrap').next('div');
				var pos = area.offset().top;
				if ($(window).width() < 768) {
					pos -= 60;
				} else {
					pos -= 50;
				}
				$('html,body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
			}
		}
	};
	var marsflagJs = function marsflagJs() {
		var el = document.createElement('script');
		if (location.pathname === '/search.html') {
			// マーズフラッグ検索結果用js
			// el.src = 'https://ce.mf.marsflag.com/latest/js/mf-search.js';
		} else {
			// マーズフラッグ検索窓用js
			el.src = 'https://ce.mf.marsflag.com/latest/js/mf-search.js';
			document.body.appendChild(el);
		}
	};
	headerMegaMene();
	// pagerListScroll();
	marsflagJs();
});
// ブラウザバック対応
window.onpageshow = function (event) {
	if (event.persisted) {
		window.location.reload();
	}
};